/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `рынок`,
    TRADE: `торговля`,
    SPOT: `спотовая торговля`,
    OPTIONS: `торговля опционами`,
    CONTRACT: `контрактная торговля`,
    ASSET: `ресурсы`,
    COPY_TRADE: `Выполнять заказы`,
    LOAN: `заем`,
    USER_CENTER: `Персональный центр`,
    SIGNIN: `Авторизоваться`,
    SIGNUP: `зарегистрироваться`,
    SLOGAN: `COIN OKX`
};
