/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `Конечно`,
    MENU_MARKETS: `первая страница`,
    MENU_ASSET: `рынок`,
    MENU_PLAN: `ресурсы`,
    MENU_USER_CENTER: `Служить`,
    LOGOUT: `Персональный центр`,
    LOGIN: `покидать`
};
