/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Заявление об условиях`,
    TERMS: `Пользовательское соглашение`,
    PRIVACY: `политика конфиденциальности`,
    COOKIE: `Политика использования файлов cookie`,
    AML: `Политика по борьбе с отмыванием денег`,
    CONDUCT_CODE: `нормы поведения`,
    CUSTOMER_SERVICE: `Обслуживание клиентов`,
    HELP_CENTER: `Справочный центр`,
    FEEDBACK: `Обратная связь`,
    CONTACTS: `Связаться с нами`,
    CHARGES: `Сборы`,
    SUPPORT: `Сервисная поддержка`,
    ABOUT_US: `о нас`,
    ANNOUNCEMENTS: `официальное заявление`,
    FAQ: `Часто задаваемые вопросы`,
    DEPOSIT_WITHDRAW_STATUS: `Статус депозита и вывода средств`,
    OFFICIAL_VERIFICATION: `Официальная проверка`,
    LANGUAGES: `язык`,
    SERVICE_EMAIL: `Сервисная электронная почта`,
    APP_DOWNLOAD: `Загрузка приложения`
};
