import { isDemoAccount } from "../../utils/localData";

/**
 * 下单相关接口
 */
export default SERVER => ({

  /**
   * 交易配置
   * method GET
   */
  CONFIG_TRADE: `${SERVER}/api/config/trade`,

  /**
   * 现货下单
   * method GET
   */
  SPOT_ORDER_OPEN: `${SERVER}/api/${isDemoAccount()?'test/':''}order/coins/create`,

  /**
   * 现货订单
   * method GET
   */
  SPOT_ORDER_LIST: `${SERVER}/api/${isDemoAccount()?'test/':''}order/coins/list`,

  /**
   * 现货订单 - 平仓
   * method POST
   */
  SPOT_ORDER_CANCEL: `${SERVER}/api/${isDemoAccount()?'test/':''}order/coins/cancel`,

  /**
   * 期权下单
   * method GET
   */
  OPTIONS_ORDER_OPEN: `${SERVER}/api/${isDemoAccount()?'test/':''}order/options/create`,

  /**
   * 期权订单列表
   * method GET
   */
  OPTIONS_ORDER_LIST: `${SERVER}/api/${isDemoAccount()?'test/':''}order/options/list`,
  
  /**
   * 期权订单详情
   * method GET
   */
  OPTIONS_ORDER_BY_ID: `${SERVER}/api/${isDemoAccount()?'test/':''}order/options/detail`,

  /**
   * 合约下单
   * method GET
   */
  CONTRACT_ORDER_OPEN: `${SERVER}/api/${isDemoAccount()?'test/':''}order/contracts/create`,

  /**
   * 合约订单
   * method GET
   */
  CONTRACT_ORDER_LIST: `${SERVER}/api/${isDemoAccount()?'test/':''}order/contracts/list`,

  /**
   * 合约订单 - 平仓
   * method POST
   */
  CONTRACT_ORDER_CLOSE: `${SERVER}/api/${isDemoAccount()?'test/':''}order/contracts/close`,

  /**
   * 合约订单 - 取消订单
   * method POST
   */
  CONTRACT_ORDER_CANCEL: `${SERVER}/api/${isDemoAccount()?'test/':''}order/contracts/cancel`,

  /**
   * 合约订单 - 修改(止损、止盈)
   * method POST
   */
  CONTRACT_ORDER_UPDATE: `${SERVER}/api/${isDemoAccount()?'test/':''}order/contracts/update`,
  
});